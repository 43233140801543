/**
* Pricing Table, Box Style
*/

.pricing-shell-wrap {
  .pricing-box {
    max-width: 420px;
  }
}

.pricing-box {
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: $gray-lighter;
  border-radius: $border-sm;
  box-shadow: $shadow-area-md;
  
  .pricing-box-price {
    color: $gray-darker;
    font-weight: 500;
    .heading-2 {
      font-weight: inherit;
    }
    > * {
      color: inherit;
    }
  }
  
  .button {
    min-width: 0;
    padding: 11px 23px;
    
  }
  
  * + .button { margin-top: 18px; }
  transition: .3s ease-out all;
}

.pricing-box-header {
  padding: 25px 10px;
  color: $white;
  background-color: $primary;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  
  > * {
    color: inherit;
  }
  
  transition: .3s ease-out all;
}

.pricing-box-body {
  padding: 28px 10px;
}

.pricing-box-list {
  letter-spacing: $spacing-sm;
  color: $gray-darker;
  > li + li { margin-top: 10px; }
}

.pricing-box-list-disable {
  color: $gray-1;
  text-decoration: line-through;
}


@include media-breakpoint-up(xxl) {
  .pricing-box-body {
    padding: 36px 10px;
  }
  .pricing-box {
    .button {
      padding: 14px 45px;
    }
  }
}




.desktop {
  .pricing-box {
    box-shadow: none;
    .pricing-box-header {
      background-color: $secondary;
    }
  }
  .pricing-box-primary,
  .pricing-box:hover {
    box-shadow: $shadow-area-md;
    
    .pricing-box-header {
      background-color: $primary;
    }
  }
}
 
* + .pricing-box-price { margin-top: 30px; }

@include media-breakpoint-up(md) {
  
  .pricing-box {
    * + .button { margin-top: 19px; }
  }
  
  * + .pricing-box-price { margin-top: 30px; }
}

@include media-breakpoint-up(xxl) {
  * + .pricing-box-price { margin-top: 40px; }
}

/**
* Pricing Box Alternative
*/

.pricing-box-alternative {
  position: relative;
  z-index: 10;
  max-width: 294px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding: 15px 20px 15px;
  border: 1px solid $gray-2;
 transition: .3s ease-out all;
  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: -40px;
    bottom: -90px;
    left: 0;
    right: 0;
    background-color: rgba($primary, 1);
    border-radius: $border-sm;
    
  }
  
  * + .pricing-box-list { margin-top: 15px; }
  * + .pricing-box-price { margin-top: 25px; }
  
  .pricing-box-button {
    margin-top: 20px;
    position: relative;
    
    .button {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;
    }
    .button-secondary {
      &:hover {
        color: $gray-darker;
        background: $secondary-1;
        border-color: $secondary-1;
      }
    }
  }
}

.pricing-box-alternative-header {
  color: $secondary;
  
  > * {
    color: inherit;
  }
}

.pricing-box-rating {
  > li {
    display: inline-block;
    margin: 0 -4px;
  }
  .mdi-star { color: $primary; }
  .mdi-star-outline { color: $gray-2; }
}

* + .pricing-box-rating { margin-top: 30px; }

.tablet,
.mobile {
  .pricing-box-alternative {
    &:before {
      visibility: visible;
      opacity: 1;
    }
  
    .pricing-box-button {
      .button {
        visibility: visible;
        opacity: 1;
      }
    }
  
    .pricing-box-rating {
      .mdi-star { color: $secondary-1; }
      .mdi-star-outline { color: $white; }
    }
  
    .pricing-box-alternative-header { color: $white; }
    .pricing-box-list { color: $white; }
    .pricing-box-list-disable { color: #d3d3d4; }
    .pricing-box-price { color: $white;
      > * { color: inherit; }
    }
  }
}

.desktop {
  .pricing-box-alternative {
    padding: 50px 20px 30px;
    margin-bottom: 0;
    z-index: 1;
    * {
      //transition: .11s cubic-bezier(0.55, 0.09, 0.68, 0.53) all;
    }
    &:before {
      visibility: hidden;
      opacity: 0;
      //transition: .3s ease-out all;
    }
  
    @extend .animated;
    
    
    &:hover {
      margin-bottom: 50px;
      padding: 15px 20px 15px;
      z-index: 10;
  
      @extend .fadeIn;
      
    
      &:before {
        visibility: visible;
        opacity: 1;
      }
    
      .pricing-box-button {
        .button {
          visibility: visible;
          opacity: 1;
        }
      }
    
      .pricing-box-rating {
        .mdi-star { color: $secondary-1; }
        .mdi-star-outline { color: $white; }
      }
    
      .pricing-box-alternative-header { color: $white; }
      .pricing-box-list { color: $white; }
      .pricing-box-list-disable { color: #d3d3d4; }
      .pricing-box-price { color: $white;
        > * { color: inherit; }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .pricing-box-alternative {
    * + .pricing-box-list { margin-top: 25px; }
  }
}

@include media-breakpoint-up(xl) {
  .pricing-box-rating {
    .icon { font-size: 36px; }
  }
}


/**
* Pricing Box LG width Style
*/

.pricing-box-lg {
  max-width: 570px;
  
  .pricing-box-header {
    padding: .9em 2.2em;
  }
  .pricing-box-body {
    padding: 28.8px 20px 28.8px 20px;
  }
  .pricing-box-list {
    display: inline-flex;
    flex-direction: column;
    padding-left: 0;
    > li {
      &:before {
        color: #b7b7b7;
      }
    }
  }
  .button {
    padding: 8px 27px;
  }
  
  .pricing-box-price-new {
    color: $gray-darker;
    font-weight: 400;
    > * {
      font-weight: inherit;
      color: inherit;
    }
  }
  .pricing-box-price-old {
    color: $gray-1;
    font-weight: 400;
    > * {
      font-weight: inherit;
      color: inherit;
      text-decoration: line-through;
    }
  }
}


* + .pricing-box-price-new {
  margin-top: 10px;
}

@include media-breakpoint-up(md) {
  .pricing-box-lg {
    .pricing-box-body {
      padding: 28.8px 50px 34.8px 45px;
    }
  }
  * + .pricing-box-price-new {
    margin-top: 0 ;
  }
}

/**
* Pricing Box Xl Style
*/
.pricing-box-xl {
  
  max-width: 380px;
  
  .pricing-box-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .button {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  
  .pricing-box-price {
    sup {
      font-size: .6em;
    }
  }
  
  .icon-md-big,
  .icon-md {
    font-size: 25px;
  }
  
  .pricing-box-body {
    padding: 40px 27px;
    
  }
  
  .pricing-box-list > li + li {
    margin-top: 23px;
  }
}

@include media-breakpoint-up(sm) {
  .pricing-box-xl {
    max-width: 620px;
    .pricing-box-body {
      padding: 50px 67px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .pricing-box-xl {
    .pricing-box-body {
      padding: 30px 27px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .pricing-box-xl {
    .pricing-box-body {
      padding: 50px 67px;
    }
    .button {
      padding-top: 11px;
      padding-bottom: 11px;
    }
    .icon-md {
      font-size: 28px;
    }
    .icon-md-big {
      font-size: 36px;
    }
  }
}

/**
* Pricing Box Wrap
*/
.pricing-box-wrap { }

@include media-breakpoint-down(md) {
  .pricing-box-wrap-element {
    .pricing-box-alternative {
      border-radius: $border-sm;
    }
  }
}

@include media-breakpoint-only(md){
  .pricing-box-wrap-element {
    &:nth-child(2n + 1) {
      .pricing-box-alternative {
        border-top-left-radius: $border-sm;
        border-bottom-left-radius: $border-sm;
      }
    }
    &:nth-child(2n + 2),
    &:last-child {
      .pricing-box-alternative {
        border-top-right-radius: $border-sm;
        border-bottom-right-radius: $border-sm;
      }
    }
  }
}

@include media-breakpoint-only(lg){
  .pricing-box-wrap-element {
    &:nth-child(3n + 1) {
      .pricing-box-alternative {
        border-top-left-radius: $border-sm;
        border-bottom-left-radius: $border-sm;
      }
    }
    &:nth-child(3n + 3),
    &:last-child {
      .pricing-box-alternative {
        border-top-right-radius: $border-sm;
        border-bottom-right-radius: $border-sm;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .pricing-box-wrap-element {
    &:nth-child(4n + 1) {
      .pricing-box-alternative {
        border-top-left-radius: $border-sm;
        border-bottom-left-radius: $border-sm;
      }
    }
    &:nth-child(4n + 4),
    &:last-child {
      .pricing-box-alternative {
        border-top-right-radius: $border-sm;
        border-bottom-right-radius: $border-sm;
      }
    }
  }
}