//
// Slick carousel
// --------------------------------------------------

// Base styles
// --------------------------------------------------

$slick-font-path: "./fonts/" !default;
$slick-font-family: $font-family-base !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}

// Appearance styles
// --------------------------------------------------

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("../images/ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: rgba($black, .6);
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 999;

  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: $slick-arrow-color;
  opacity: $slick-opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0;
  [dir="rtl"] & {
    left: auto;
    right: 0;
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: 0;
  [dir="rtl"] & {
    left: 0;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-slider {
  margin-bottom: 30px;

  *:focus {
    outline: 0;
  }
}

.slick-dots {
  display: block;
  margin-top: 20px;
  list-style: none;
  width: 100%;
  padding: 0;
  text-align: center;
  font-size: 0;
  line-height: 0;
  word-spacing: 0;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      @include reset-button;
      width: 8px;
      height: 8px;
      border-radius: 100px;
      background: $gray;
    }

    &.slick-active button,
    &:hover button {
      background: $primary;
    }
  }
}

/**
* Slick widget
*/
.slider-widget {
  width: 100%;
  max-width: 100%;
  background: $white;
  border-radius: $border-sm;
  box-shadow: 0 5px 12px 0 rgba(#dcdeec, 1);
  overflow: hidden;

  .slick-slider {
    margin: 0;
  }

  .item > * { padding: 20px; }
}

* + .slider-widget { margin-top: 30px; }

.slider-widget-aside {
  @include display-flex;
  @include align-items(center);
  @include justify-content(space-between);
  padding: 5px;
  color: $white;
  background: $secondary;
  text-align: center;

  .slick-prev,
  .slick-next {
    position: relative;
    top: auto;
    transform: none;
    width: 100%;
    height: auto;
    color: $white;
    background-color: transparent;

    &::before {
      font-size: 30px;
      opacity: 1;
      transition: .33s;
    }

    &:hover {
      &::before {
        color: rgba($white, .7);
      }
    }
  }

  .slick-prev {
    &::before {
      content: '\f14c';
    }
  }
  
  .slick-next {
    &::before {
      content: '\f151';
    }
  }

  .slick-prev + .slick-next { margin-top: 5px; }
}

@include media-breakpoint-up(sm) {
  .slider-widget {
    @include display-flex;
    @include flex-direction(row);

    .slick-slider {
      width: calc(100% - 70px);
    }

    .slider-widget-aside {
      @include flex-direction(column);
      width: 70px;
      padding: 20px 5px;
    }
  }
}

@include media-breakpoint-up(md) {
  .slider-widget {
    .slick-slider {
      .item > * {
        padding: 30px 40px;
      }
    }
  }
}


@include media-breakpoint-up(xl) {
  * + .slider-widget { margin-top: 40px; }
}

.slick-slider[data-vertical='true'] {
  .slick-slide {
    width: 100%;
    float: none;
  }
}

