/*
*
* Counter
* --------------------------------------------------
*/

.counter {

  &__subtitle { }

  &-wrap {
    font-size: 24px;

    .icon {
      font-size: 28px;
    }

    * + p {
      margin-top: 10px;
    }

    .icon + * {
      margin-top: 8px;
    }
  }
}

@include media-breakpoint-between(md, xl)  {
  .cell-sm-3 {
    .counter {
      &-wrap {
        font-size: 20px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .counter {
    &-wrap {
      .icon {
        font-size: 32px;
      }
    }
  }
}