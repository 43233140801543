/*
* Responsive units
*/

@include unit-responsive(20px, 30px);

//
// Unit Spacing 
// 
.unit-spacing-xs {
  @include unit-spacing(7px, 15px);
}
.unit-spacing-md {
  @include unit-spacing(30px, 20px);

  @include media-breakpoint-up(xl) {
    @include unit-spacing(65px, 20px);
  }
}
.unit-spacing-xxl {
  @include unit-spacing(15px, 10px);
  @include media-breakpoint-up(md) {
    @include unit-spacing( 115px, 20px);
  }
}
