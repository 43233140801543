/*
*
* Swiper
* --------------------------------------------------
*/

.swiper-container {
  @include display-flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  height: auto;
  min-height: 45vw;
  /* Fix of Webkit flickering */
  z-index: 1;

  .button-default-outline {
    @include btn-variant($white, transparent, $white, $white, $secondary, $secondary);
  }
}

.swiper-container-vertical > .swiper-wrapper {
  @include flex-direction(column);
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
  @include display-flex;
  @include align-self(stretch);
  @include align-items(stretch);
  width: 100%;
  height: auto;
  min-height: inherit;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
  will-change: transform;
}

.swiper-container-multirow > .swiper-wrapper {
  @include flex-wrap(wrap);
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  position: relative;
  @include display-flex;
  @include align-items(center);
  @include flex-shrink(0);
  width: 100%;
  background-size: cover;
  background-position: center center;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1000;
  pointer-events: none;
  opacity: 0;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  border-radius: 50%;
  text-align: center;
  margin-top: -20px;
  z-index: 10;
  cursor: pointer;
  background: $secondary;
  color: $white;
  transition: .33s;
  
  &::before {
    position: relative;
    font-family: 'Material Design Icons';
  }
  
  &:hover {
    background: $primary;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev {
  left: 40px;
  
  &::before {
    content: '\f14b';
    left: -1px;
  }
}

.swiper-button-next {
  right: 40px;
  
  &::before {
    content: '\f150';
    right: -1px;
  }
}

/* Pagination Styles */
.swiper-pagination-wrap {
  position: absolute;
  bottom: 30px;
  left: 50%;
  z-index: 10;
  text-align: center;
  transform: translate3d(-50%, 0, 0);
  
  &.container-bigger {
    width: 100%;
    text-align: left;
  }
}

@include media-breakpoint-up(xl) {
  .swiper-pagination-wrap {
    bottom: 50px;
    
    &.container-bigger {
      bottom: 75px;
    }
  }
}

.swiper-pagination {
  position: relative;
  display: inline-block;
  font-size: 0;
  line-height: 0;
}

.swiper-pagination-bullet {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: rgba($white, .3);
  box-shadow: 0 0 0 0 rgba($black, .5);
  transition: .33s;
  
  &:hover {
    background: $white;
  }
  
  &-active {
    background: $secondary-1;
  }
}

$pagination-spacing: 30px;

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-container-vertical > .swiper-pagination {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal .swiper-pagination .swiper-pagination-bullet {
  margin: 0 $pagination-spacing / 2;
}

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-right {
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

/* Cube */
.swiper-container-cube {
  overflow: visible;
}

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  visibility: hidden;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

/* Scrollbar */
.swiper-scrollbar {
  position: relative;
  -ms-touch-action: none;
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  bottom: 0;
  z-index: 50;
  height: 3px;
  width: 100%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $primary;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.swiper-slide > .vide__body,
.swiper-slide > .parallax_cnt {
  height: 100%;
}

.swiper-slide-caption {
  width: 100%;
}

//@include media-breakpoint-up(xl) {
//  .swiper-slider {
//    h4,
//    .heading-4 {
//      font-size: 25px;
//    }
//  }
//}

// Style 1
// --------------------------
.swiper-slider_height-1 {
  min-height: 300px;
  @include media-breakpoint-up(md) {
    min-height: 650px;
  }
  @include media-breakpoint-up(xl) {
    min-height: 512px;
  }
}

.swiper-slider_height-2 {
  min-height: 300px;
  @include media-breakpoint-up(md) {
    min-height: 47.5vw;
  }
}

.swiper-slider_height-3 {
  min-height: 300px;
  @include media-breakpoint-up(md) {
    min-height: 450px;
  }
  @include media-breakpoint-up(lg) {
    min-height: 600px;
  }
}

.swiper-slider_height-4 {
  min-height: 300px;
  @include media-breakpoint-down(sm) {
    [class*='section'] {
      padding-bottom: 75px;
    }
  }
  @include media-breakpoint-up(md) {
    min-height: 500px;
  }
  
  @include media-breakpoint-up(lg) {
    min-height: 39.9vw;
  }
}

.swiper-type-1 {
  min-height: 300px;
  
  .big {
    font-weight: 400;
  }
  
  .swiper-slide {
    @include align-items(flex-end);
  }
  
  .swiper-pagination {
    margin-left: -10px;
  }
  
  .swiper-buttons {
    display: inline-block;
    position: absolute;
    bottom: 30px;
    left: $grid-gutter-width / 2;
    white-space: nowrap;
    
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
      display: inline-block;
      left: auto;
      right: auto;
      margin-top: 0;
      transform: none;
    }
    
    > * + * {
      margin-left: 8px;
    }
  }
  
  @include media-breakpoint-down(sm) {
    [class*='section'] {
      padding-top: 25px;
      padding-bottom: 90px;
    }
    
    .swiper-slide {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background: linear-gradient(to bottom, rgba($black, .5) 0%, rgba($black, .2) 50%, rgba($black, 0) 100%);
      }
      
      .swiper-slide-caption {
        position: relative;
        z-index: 2;
      }
    }
  }
  
  @include media-breakpoint-up(sm) {
    .swiper-buttons {
      left: calc(50% - #{480px - $grid-gutter-width} / 2);
    }
  }
  
  @include media-breakpoint-up(md) {
    min-height: 500px;
    
    .swiper-pagination-wrap {
      display: none;
    }
    
    .swiper-buttons {
      left: calc(50% - 720px / 2);
    }
  }
  
  @include media-breakpoint-up(lg) {
    min-height: 39.4vw;
    
    .swiper-buttons {
      left: calc(50% - 940px / 2);
    }
  }
  
  @include media-breakpoint-up(xl) {
    .swiper-buttons {
      left: calc(50% - 1170px / 2);
      bottom: 50px;
    }
  }
  
  @include media-breakpoint-up(xxl) {
    .section-xl {
      padding-top: 180px;
      padding-bottom: 180px;
    }
    
    .swiper-buttons {
      left: calc(50% - 1570px / 2);
    }
  }
  
  @include media-breakpoint-up (xxxl) {
    .swiper-buttons {
      left: calc(50% - 1770px / 2);
      bottom: 95px;
    }
  }
}

// Swiper group
.swiper-group {
}

.rd-navbar-static-linked {
  .page-header + .section-parallax-swiper {
    margin-top: -60px;
    .swiper-slide-caption {
      padding-top: 180px;
    }
  }
}

/**
*  Swiper main
*/

.swiper-main {
  
  .swiper-pagination-wrap {
    bottom: 45px;
  }
  .button-default-outline {
    @include btn-variant($white, transparent, $gray-2, $white, $primary, $primary);
  }
  
  & ~ .breadcrumbs-custom__shape {
    width: 160vw;
  }
  
  @include media-breakpoint-up(sm) {
    & ~ .breadcrumbs-custom__shape {
      width: 120vw;
    }
  }
  
  @media (max-width: 767px) {
    .breadcrumbs-custom-title {
      font-size: 45px;
      
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
      }
      
      &::before {
        left: -8px;
      }
      
      &::after {
        right: -4px;
      }
    }
    .breadcrumbs-custom-subtitle {
      font-size: 15px;
    }
    .heading-2 {
      display: none;
    }
    .button {
      min-width: 180px;
      padding: 7px 15px;
      font-size: 13px;
    }
    * + .group-md {
      margin-top: 40px;
    }
  }
  @include media-breakpoint-up(md) {
    & ~ .breadcrumbs-custom__shape {
      width: 100vw;
    }
    .breadcrumbs-custom-title {
      font-size: 86px;
    }
    .breadcrumbs-custom-subtitle {
      font-size: 18px;
    }
    .heading-2 {
      font-size: 28px;
    }
    * + .group-md {
      margin-top: 45px;
    }
    .button {
      padding: 12px 18px;
      font-size: 16px;
    }
  }
  @include media-breakpoint-up(lg) {
    .breadcrumbs-custom-title {
      font-size: 116px;
      line-height: 1.3;
    }
    .swiper-pagination-wrap {
      bottom: 55px;
    }
    .breadcrumbs-custom-subtitle {
      font-size: 20px;
    }
    .heading-1 + p {
      margin-top: 10px;
    }
    * + .group-md {
      margin-top: 55px;
    }
  }
  @media (min-width: 1200px) and (min-height: 700px) {
    .breadcrumbs-custom-title {
      font-size: 150px;
      line-height: 1.4;
      margin-top: 0;
      
      &::after {
        right: -10px;
      }
    }
    * + .group-md {
      margin-top: 65px;
    }
    
  }
  @media (min-height: 800px) and (min-width: 1400px) {
    .breadcrumbs-custom-subtitle {
      font-size: 24px;
    }
    .heading-2 {
      font-size: 34px;
    }
    .button {
      padding: 15px 38px;
      font-size: 19px;
    }
  }
  
  @include media-breakpoint-up(xxl) {
    .breadcrumbs-custom-title {
      font-size: 190px;
      line-height: 1.3;
      
      &:before,
      &:after {
        width: 20px;
        height: 20px;
      }
      &:before {
        left: -65px;
      }
      &:after {
        right: -40px;
      }
    }
    .breadcrumbs-custom-subtitle {
      font-size: 30px;
    }
    .heading-2 {
      font-size: 42px;
      margin-top: 20px;
    }
    .swiper-pagination-wrap {
      bottom: 85px;
    }
    .button-lg {
      font-size: 19px;
      padding: 15px 65px;
    }
    * + .group-md {
      margin-top: 70px;
    }
  }
  @include media-breakpoint-up (xxxl) {
    .breadcrumbs-custom-title {
      font-size: 220px;
      line-height: 1.4;
      &:before,
      &:after {
        width: 27px;
        height: 27px;
      }
      &:before {
        left: -90px;
      }
      &:after {
        right: -65px;
      }
    }
    .swiper-pagination-wrap {
      bottom: 105px;
    }
    .heading-2 {
      font-size: 52px;
    }
  }
}

.swiper-main-section {
  padding-top: 60px;
  padding-bottom: 100px;
}

.desktop {
  @include media-breakpoint-up(lg) {
    .swiper-main-section {
      padding-top: 200px;
      padding-bottom: 130px;
    }
  }
  @include media-breakpoint-up(xl) {
    .swiper-main-section {
      padding-top: 180px;
      padding-bottom: 160px;
    }
  }
  
  @include media-breakpoint-up(xxl) {
    .swiper-main-section {
      padding-bottom: 220px;
    }
  }
  
  @include media-breakpoint-up (xxxl) {
    .swiper-main-section {
      padding-top: 230px;
      padding-bottom: 245px;
    }
  }
}

.swiper-modern {
  min-height: 480px;
}

.rd-navbar-fixed-linked {
  .swiper-modern {
    min-height: 80vh;
  }
  &.landscape {
    .swiper-modern {
      min-height: 100vh;
    }
  }
  
  @include media-breakpoint-up(md) {
    &,
    &.landscape {
      .swiper-modern {
        min-height: 570px;
      }
    }
  }
}

.desktop {
  .swiper-modern {
    @include media-breakpoint-up(lg) {
      height: 100vh;
      min-height: 600px;
      &.swiper-modern-style-1 {
        height: auto;
      }
    }
    @include media-breakpoint-up(xxl) {
      &.swiper-modern-style-1 {
        min-height: 800px;
      }
    }
  }
}

// Swiper for trucking landing
.swiper-align-left {
  @include media-breakpoint-up(md) {
    h3 + * {
      margin-top: 20px;
    }
    * .button {
      margin-top: 30px;
    }
    .swiper-pagination-wrap {
      width: 100%;
      text-align: left;
    }
  }
  &-custom {
    @include media-breakpoint-up(xl) {
      h3 + .divider {
        margin-top: 40px;
      }
    }
  }
}

.swiper-form-wrap {
  position: relative;
  
  @include media-breakpoint-up(lg) {
    .form-request-wrap {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      left: 0;
      right: 0;
      z-index: 2;
      pointer-events: none;
    }
    .form-request {
      pointer-events: auto;
    }
  }
}

.swiper-modern-1.swiper-container.swiper-slider.swiper-modern {
  [class*='swiper-button-'] {
    display: none !important;
    @include media-breakpoint-up(xl) {
      display: block !important;
    }
  }
}