/**
* Instagram Plugin Style
*/

/**
* Profile
*/
.instafeed-profile {
  text-align: center;
}

.instafeed-profile-picture {
  
  > img {
    border-radius: 50%;
    width: 180px;
    height: 180px;
  }
  
}

.instafeed-profile-username {
  position: relative;
  text-transform: capitalize;
  color: $secondary;
  
  > * {
    color: inherit;
    text-transform: inherit;
  }
  
  &:after {
    margin-top: 30px;
    display: inline-block;
    content: '';
    width: 60px;
    border-bottom: 2px solid $gray-2;
  }
  
}

.instafeed-profile-meta {
  letter-spacing: $spacing-sm;
  
  > .half + .half {
    margin-top: 10px;
  }
  
}

.instafeed-profile-button {
  
  .button {
    min-width: 112px;
  }
  
}

* + .instafeed-profile-username { margin-top: 25px; }

* + .instafeed-profile-meta { margin-top: 20px; }

* + .instafeed-profile-button { margin-top: 30px; }

/**
*  Thumbnail InstaFeedl
*/
 
.thumbnail-instafeed {
  position: relative;
  padding-bottom: 100%;
  
  
  a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
  
  .caption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: rgba($primary, .35);
    transition: .3s ease-out all;
    pointer-events: none;
    overflow: hidden;
    will-change: transfrom;
    
    .list-inline {
      margin-left: -10px;
      margin-right: -10px;
      > li {
        padding-left: 10px;
        padding-right: 10px;
      }
      
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    
    .icon {
      margin-right: 5px;
      font-size: 28px;
    }
  }
}

.ie-10,
.ie-11 {
  .thumbnail-instafeed {
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      max-width: 135%;
      max-height: 135%;
      min-height: 101%;
      min-width: 101%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .thumbnail-instafeed {
    .caption {
      .list-inline {
        margin-left: -25px;
        margin-right: -25px;
        > li {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }
  }
}

/**
*  Thumbnail InstaFeedl Minimal
*/
.thumbnail-instafeed-minimal {
  .caption {
    .list-inline {
      > li {
        display: block;
      }
      > li + li { margin-top: 10px; }
    }
  }
}

.desktop {
  @include media-breakpoint-up(lg) {
    .thumbnail-instafeed {
      .caption {
        background-color: rgba($primary, .8);
      }
      .caption,
      .list-inline > li {
        visibility: hidden;
        opacity: 0;
      }
      .list-inline > li {
        transform: scale3d(0, 0, 0);
        will-change: transfrom;
      }
      &:hover {
        .caption,
        .list-inline > li {
          visibility: visible;
          opacity: 1;
        }
        .list-inline > li {
          transform: scale3d(1, 1, 1);
          transition: 480ms cubic-bezier(0.18, 0.89, 0.32, 1.28) transform, 150ms cubic-bezier(0.18, 0.89, 0.32, 1.28) opacity;
          transition-delay: .15s;
        }
      }
    }
  }
}

* + .instafeed { margin-top: 40px; }
.range.instafeed + .button { margin-top: 40px;}

@include media-breakpoint-up(lg) {
  * + .instafeed { margin-top: 60px; }
  .range.instafeed + .button { margin-top: 60px;}
}

/**
* Instafeed Vertical
*/
.thumbnail-instafeed-vertical {
  .list-inline {
    > li {
      display: block;
      & + li {
        margin-top: 20px;
      }
    }
  }
}