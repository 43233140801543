//Color theme main
//-----------------------------------
$gray-darker:             #29293a; //
$gray-dark:               #333333;
$gray:                    #555555;
$gray-light:              #838386; //
$gray-lighter:            #f2f3f9; //

$gray-1:                  #c2c2c3; //
$gray-2:                  #ededed; //
$gray-3:                  #7f839a; //
$gray-4:                  #d8dae2; //

$primary:                 #981df0; //

$secondary:               #ed1c94; //
$secondary-1:             #ffec17; //
$secondary-2:             #6d0eb1; //
$secondary-3:             #080ab4; //
$secondary-4:             #6e7b9d; //
$secondary-7:             #6a0dac; //

$secondary-5:             #21212e; //
$secondary-6:             #dedfe8; //

$gray-100: #edeff4;
$gray-200: #d7d7d7;
$gray-300: #cccccc;
$gray-400: #b7b7b7;
$gray-500: #838386;
$gray-600: #868e96;
$gray-700: #2c343b;
$gray-800: #29293a;
$gray-900: #111111;