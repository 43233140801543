//
// Countdown
// --------------------------------------------------

.countdown {
  font-family: $font-family-sec;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: $spacing-md;
  text-transform: uppercase;
  color: $gray-darker;
 
  
  &.countdown-expired {
    color: $gray-1;
  }
}

* + .countdown {
  margin-top: 20px;
}

.countdown + .countdown {
  margin-top: 15px;
}

@include media-breakpoint-up(md) {
  .countdown {
    font-size: 22px;
  }
}

@include media-breakpoint-up(xl) {
  .countdown {
    font-size: 28px;
  }

  * + .countdown {
    margin-top: 40px;
  }

  .countdown + .countdown {
    margin-top: 30px;
  }
}