.lg-outer {
  // reset transition duration
  &.lg-css3.lg-zoom-dragging {
    .lg-item.lg-complete.lg-zoomable {
      .lg-img-wrap, .lg-image {
        @include transition-duration(0s);
      }
    }
  }
  &.lg-use-transition-for-zoom {
    .lg-item.lg-complete.lg-zoomable {
      .lg-img-wrap {
        @include transitionCustom(transform $zoom-transition-duration cubic-bezier(0, 0, 0.25, 1) 0s);
      }
    }
  }
  &.lg-use-left-for-zoom {
    .lg-item.lg-complete.lg-zoomable {
      .lg-img-wrap {
        @include transitionCustom(left $zoom-transition-duration cubic-bezier(0, 0, 0.25, 1) 0s, top $zoom-transition-duration cubic-bezier(0, 0, 0.25, 1) 0s);
      }
    }
  }

  .lg-item.lg-complete.lg-zoomable {

    .lg-img-wrap {
      @include translate3d(0, 0, 0);
      @include backface-visibility(hidden);
    }

    .lg-image {
      // Translate required for zoom
      @include scale3d(1, 1, 1);
      @include transitionCustom(transform $zoom-transition-duration cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important);
      @include transform-origin(0 0);
      @include backface-visibility(hidden);
    }
  }

}

// zoom buttons
#lg-zoom-in {
  &:after {
    content: "\e311";
  }
  @media (max-width: 479px) {
    display: none;
  }
}

#lg-actual-size {
  font-size: 20px;
  &:after {
    content: "\e033";
  }
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;

  &:after {
    content: "\e312";
  }

  .lg-zoomed & {
    opacity: 1;
    pointer-events: auto;
  }
  @media (max-width: 479px) {
    display: none;
  }
}