/*
*
* RD Parallax
* --------------------------------------------------
*/

.rd-parallax {

  &-inner {
    position: relative;
    overflow: hidden;
    transform: translate3d(0px, 0px, 0px);
    clip: rect(0, auto, auto, 0);
    pointer-events: none;
  }

  &-layer[data-type="media"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &-layer-holder {
    pointer-events: all;
  }

  &-layer[data-url] {
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
  }

  // Styles for Parallax with slider
  &-swiper {
    z-index: 1 !important;
  }
}

.parallax-container {
  position: relative;
  overflow: hidden;
}

.material-parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(-50%);
}

.parallax-content {
  position: relative;
  z-index: 1;
}

// parallax text
//-----------------------------------
.parallax-text-wrap {
  position: relative;

  h3 {
    position: relative;
    z-index: 1;
  }

  & + * {
    position: relative;
  }
}

.parallax-text {
  position: absolute;
  bottom: -20%;
  left: 0;
  z-index: 0;
  display: none;
  font: 900 36px/1 $font-family-sans-serif-3;
  letter-spacing: .28em;
  text-transform: uppercase;
  white-space: nowrap;
  color: $gray-lighter;
}

@include media-breakpoint-up(md) {
  .parallax-text {
    font-size: 70px;
    bottom: -60%;
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .parallax-text {
    font-size: 100px;
  }
}

@include media-breakpoint-up(xl) {
  .parallax-text {
    font-size: 110px;
    left: -95px;
  }
}

@include media-breakpoint-up(xxl) {
  .parallax-text {
    font-size: 150px;
    left: -145px;
  }
}

@include media-breakpoint-up (xxxl) {
  .parallax-text {
    font-size: 170px;
  }
}

.some-wrap {
  .heading-group {
    will-change: transform;
    transform: translateY(var(--ty, 0)) rotateX(var(--rx, 0)) rotateY(var(--ry, 0));
  }
  .button-group {
    will-change: transform;
    transform: translateY(var(--ty, 0)) rotateX(var(--rx, 0)) rotateY(var(--ry, 0));
  }
}