/*
*
* RD Navbar
* --------------------------------------------------
*/


//== RD Navbar variables
//
//##

$rd-navbar-min-font-size: 24px;
$rd-navbar-min-line-height: 48px;
$rd-navbar-min-height: 56px;

$rd-navbar-color: $gray-dark;
$rd-navbar-background: $white;
$rd-navbar-shadow: 0 6px 8px rgba($secondary-4, .17);
$rd-navbar-width: 1800px;

//RD Navbar panel
$rd-navbar-panel-color: $rd-navbar-color;
$rd-navbar-panel-background: $rd-navbar-background;

//RD Navbar nav
$rd-navbar-nav-min-width: 270px;
$rd-navbar-nav-color: $rd-navbar-color;
$rd-navbar-nav-background: transparent;
$rd-navbar-nav-hover-color: $secondary;
$rd-navbar-nav-hover-background: transparent;
$rd-navbar-nav-active-color: $rd-navbar-nav-hover-color;
$rd-navbar-nav-active-background: $rd-navbar-nav-hover-background;

//RD Navbar dropdown
$rd-navbar-dropdown-width: 310px;
$rd-navbar-dropdown-background: $white;
$rd-navbar-dropdown-item-color: $gray-darker;
$rd-navbar-dropdown-item-background: transparent;
$rd-navbar-dropdown-item-hover-color: $secondary;
$rd-navbar-dropdown-item-hover-background: transparent;
$rd-navbar-dropdown-item-active-color: $rd-navbar-dropdown-item-hover-color;
$rd-navbar-dropdown-item-active-background: $rd-navbar-dropdown-item-hover-background;

//RD Navbar megamenu
$rd-navbar-megamenu-gutter: 30px;
$rd-navbar-megamenu-background: $white;
$rd-navbar-megamenu-item-color: $black;
$rd-navbar-megamenu-item-background: transparent;
$rd-navbar-megamenu-item-hover-color: $secondary;
$rd-navbar-megamenu-item-hover-background: transparent;

//RD Navbar togles
$rd-navbar-toggle-preset: "button-lines-arrow-2";
$rd-navbar-collapse-toggle-preset: "collapse-preset-1";

//RD Navbar Fixed
$rd-navbar-fixed-height: $rd-navbar-min-height;
$rd-navbar-fixed-line-height: $rd-navbar-min-line-height;
$rd-navbar-fixed-toggle-color: $gray-darker;

$rd-navbar-fixed-panel-color:  $gray-darker;
$rd-navbar-fixed-panel-background:  $white;

$rd-navbar-fixed-nav-color: $rd-navbar-color;
$rd-navbar-fixed-nav-background: $rd-navbar-background;

$rd-navbar-fixed-nav-active-color: $white;
$rd-navbar-fixed-nav-active-background: $primary;

$rd-navbar-fixed-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.25);

//RD Navbar indents
$navbar-static-nav-indent: 38px;
$navbar-fullwidth-nav-indent: 20px;


@import "rd-navbar_includes/_rd-navbar-mixins";


// RD Navbar Basics

.breadcrumbs-custom-wrap .rd-navbar-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1091;

  .rd-navbar {
    background: transparent;
  }

  .rd-navbar--is-stuck {
    &:before {
      opacity: 1;
    }
  }
}

%rd-navbar-transition {
  transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
}

%rd-navbar-hidden {
  display: none;
}

%rd-navbar-visible {
  display: block;
}

.rd-navbar--no-transition {
  &, * {
    transition: none !important;
  }
}

.rd-navbar-wrap {
  @extend %rd-navbar-transition;
}

.rd-navbar, .rd-navbar.rd-navbar--is-clone {
  @extend %rd-navbar-hidden;
}

.rd-navbar-fixed,
.rd-navbar-static,
.rd-navbar-fullwidth,
.rd-navbar-sidebar {
  @extend %rd-navbar-visible;
}

.rd-navbar,
.rd-navbar-dropdown,
.rd-navbar-megamenu,
.rd-navbar-nav,
.rd-navbar-panel{
  @extend %rd-navbar-transition;
}


// RD Navbar Collapse
.rd-navbar-collapse {
  &-toggle {
    @include make-toggle(
            $rd-navbar-collapse-toggle-preset,
            $rd-navbar-min-line-height,
            $rd-navbar-min-font-size,
            $rd-navbar-panel-color
    );
    display: none;
  }
}

.rd-navbar--is-stuck {
  box-shadow: $rd-navbar-shadow;
}

.rd-navbar.rd-navbar-fixed + .rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar + .rd-navbar.rd-navbar--is-clone {
  display: none;
}

@import "rd-navbar_includes/_rd-navbar-components";

// breadcrumbs custom offset
.desktop {
  .rd-navbar-wrap + .breadcrumbs-custom {
    @include media-breakpoint-up(lg) {
      padding-top: 200px;
      padding-bottom: 70px;

      &.breadcrumbs-blog-post {
        padding-bottom: 50px;
      }
    }
    @include media-breakpoint-up(xl) {
      padding-top: 170px;
      padding-bottom: 80px;
    }
    @include media-breakpoint-up(xxl) {
      padding-top: 235px;
      padding-bottom: 100px;

      &.breadcrumbs-custom-lg {
        padding-top: 215px;
        padding-bottom: 160px;
      }
    }
  }
}
.bg-gray-darker,
.bg-gradient-2,
.bg-gradient-1,
.bg-image,
.bg-secondary-2,
.bg-gradient {
  .logo-inverse {
    display: block;
  }
  .logo-default {
    display: none;
  }
  .rd-navbar-toggle,
  .rd-navbar-sidebar-toggle{
    span {
      &,
      &:before,
      &:after {
        background: $white;
      }
    }
  }

  .rd-navbar-fixed {
    .rd-navbar-panel {
      &:before {
        background: linear-gradient(-39deg, #470cb2 10%, $secondary-2 40%);
      }
    }
  }

  .rd-navbar-fixed,
  .rd-navbar-fullwidth,
  .rd-navbar-static {
    .rd-navbar-search .rd-navbar-search-toggle,
    .rd-navbar-shop a {
      color: $white;

      &:hover {
        color: $primary;
      }
    }
  }

  .rd-navbar-static,
  .rd-navbar-fullwidth {
    .rd-navbar-nav {
      > li {
        > a {
          color: $white;
          &:hover {
            color: $secondary;
            &:before {
              background-color: $secondary;
            }
          }
        }
        > .rd-navbar-submenu-toggle {
          color: $white;
          &:hover {
            color: $secondary;
          }
        }
        &.focus,
        &.opened {
          > a {
            color: $secondary;
            &:before {
              background-color: $secondary;
            }
          }

          > .rd-navbar-submenu-toggle {
            color: $secondary;
          }
        }

        &.active {
          > a {
            color: $secondary;
            &:before {
              background-color: $secondary;
            }
          }

          > .rd-navbar-submenu-toggle {
            color: $secondary;
          }
        }
      }
    }
  }
}


/*
* @subsection   Hybrid  Styles
*/

//=============    Static & Fullwidth     ================
.rd-navbar-static,
.rd-navbar-fullwidth {

    // RD Navbar Nav
  .rd-navbar-nav {
    text-align: center;
    > li {
      position: relative;
      display: inline-block;
      transition: .25s;

      &.rd-navbar--has-megamenu {
        position: static;
      }

      > a {
        position: relative;
        display: inline-block;
        color: $rd-navbar-nav-color;
        line-height: 1;
        transition: .25s;
        vertical-align: middle;

        &:hover{
          color: $rd-navbar-nav-hover-color;
        }
      }

      > .rd-navbar-submenu-toggle{
        position: relative;
        font-family: "Material Design Icons";
        font-size: 0;
        cursor: pointer;
        vertical-align: middle;

        &:hover{
          color: $rd-navbar-nav-hover-color;
        }

        &:before{
          content: '\f236';
          position: absolute;
          font-size: 18px;
          top: 1px;
          left: 2px;
          line-height: 0;
        }

        .ie-10 &,
        .ie-11 &,
        .ie-edge & {
          font-size: 18px;
          &:before{
            line-height: 1.2;
          }
        }
      }

      &.focus,
      &.opened{
        > a{
          color: $rd-navbar-nav-hover-color;
          background: $rd-navbar-nav-hover-background;
        }

        > .rd-navbar-submenu-toggle{
          color: $rd-navbar-nav-hover-color;
        }
      }

      &.active {
        > a {
          color: $rd-navbar-nav-active-color;
          background: $rd-navbar-nav-active-background;
        }

        > .rd-navbar-submenu-toggle{
          color: $rd-navbar-nav-active-color;
        }
      }
    }
  }

  // RD Navbar Inner
  .rd-navbar-inner {
    position: relative;

    max-width: $rd-navbar-width;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up (xxxl) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  // RD Navbar top panel
  .rd-navbar-top-panel{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $gray-darker;
    color: $white;

    &-inner{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: $rd-navbar-width;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-up (xxxl) {
        padding-left: 70px;
        padding-right: 70px;
      }
    }

    a{
      color: $white;
      &:hover{
        color: $secondary-1;
      }
    }

    a.icon-gray-1 {
      color: $gray-1;
      &:hover{
        color: $white;
      }
    }
  }

  //RD Navbar aside center
  .rd-navbar-aside-center {
    display: flex;
    align-items: center;
  }

  .rd-navbar-shop {
    font-size: 24px;
    span {
      font-size: 16px;
      padding-left: 10px;
    }

    .rd-navbar-shop-icon {
      color: $rd-navbar-color;
      &:hover{
        color: $rd-navbar-nav-hover-color;
      }
    }
  }

  .rd-navbar-shop,
  .rd-navbar-search,
  .rd-navbar-search-1 {
    position: relative;
    display: inline-flex;
    margin-left: 30px;
    vertical-align: middle;
  }

  @include media-breakpoint-up (xxxl) {
    .rd-navbar-shop,
    .rd-navbar-search {
      margin-left: 50px;
    }
  }

  // RD Navbar Search
  .rd-navbar-search,
  .rd-navbar-search-1 {


    &.active{
      .rd-search {
        visibility: visible;
        opacity: 1;
      }
    }

    .form-label {
      font-size: 18px;
    }

    // Make toggle for sidebar
    .rd-navbar-search-toggle{
      display: inline-flex;
      color: $rd-navbar-panel-color;
      font-size: 30px;

      &:hover{
        color: $rd-navbar-nav-hover-color;
      }

      @include toggle-icons-via-rotation(32px, 32px, 26px, '\f43b', '\f24c', 'Material Design Icons', 'Material Design Icons');
    }

    .form-wrap{
      margin-bottom: 0;
    }

    .form-input{
      padding-right: 50px;
      box-shadow: 0 2px 5px rgba($gray-darker, .05);
    }

    .rd-search{
      position: absolute;
      top: calc(100% + 29px);
      right: 0;

      width: 380px;

      opacity: 0;
      visibility: hidden;

      transition: .3s;
      z-index: 3;
    }

    .rd-search-form-submit{
      position: absolute;
      top: 17px;
      right: 0;

      width: 50px;
      padding: 0;
      border: none;

      font-size: 26px;

      background-color: transparent;
    }
  }

  // RD Navbar Dropdown
  .rd-navbar-nav {
    > li > .rd-navbar-dropdown {
      position: absolute;
      left: 0;

      width: $rd-navbar-dropdown-width;
      background: $rd-navbar-dropdown-background;
      z-index: 5;

      &.rd-navbar-open-left {
        right: 0;
        left: auto;
      }
    }

    li.focus,
    li.opened {
      > .rd-navbar-dropdown {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    li.focus,
    li.opened {
      > .rd-navbar-megamenu {
        opacity: 1;
        visibility: visible;
        transform: translate3d(-50%, 0, 0);
      }
    }

    > li {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        z-index: 15;
        visibility: hidden;
      }

      > .rd-navbar-dropdown {

        .rd-navbar-dropdown {
          left: 100%;
          top: 0;
          margin-top: -8px;
          margin-left: 8px;
          z-index: 2;
          transform: translate(30px, 0);
        }

        > li.focus,
        > li.opened {
          > .rd-navbar-dropdown {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    position: absolute;
    top: 100%;

    display: block;
    padding: 8px 0;
    margin-top: 0;

    visibility: hidden;
    opacity: 0;

    text-align: left;
    border-radius: 6px;
    box-shadow: $rd-navbar-shadow;
    @extend %rd-navbar-transition;

    li{
      > a{
        font-size: 16px;
        transition: .15s ease-in-out;

        &:before{
          content: '\f14f';
          font-family: "Material Design Icons";
          margin-left: -20px;
          transition: opacity .15s ease-in-out, .15s margin ease-in-out;
          opacity: 0;
          padding-right: 5px;
        }
      }

      &.focus > a,
      &.opened > a,
      > a:hover{
        &:before{
          margin-left: 0;
          opacity: 1;
        }
      }
    }
  }

  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    width: $rd-navbar-dropdown-width;
    background: $rd-navbar-dropdown-background;
    padding: 5px 28px;
    transform: translateY(30px);

    > li{
      > a {
        display: block;
        padding: 13px 20px 13px 0;
        color: $rd-navbar-dropdown-item-color;
        background: $rd-navbar-dropdown-item-background;

        &:hover{
          color: $rd-navbar-dropdown-item-hover-color;
          background: $rd-navbar-dropdown-item-hover-background;
        }
      }

      &.focus,
      &.opened{
        > a{
          color: $rd-navbar-dropdown-item-active-color;
          background: $rd-navbar-dropdown-item-active-background;
        }
      }
    }
  }

  // RD Navbar Megamenu
  .rd-navbar-megamenu {
    left: 50%;

    display: flex;
    width: 98%;
    max-width: $rd-navbar-width;
    padding: 10px 40px 30px;
    transform: translate3d(-50%, 30px, 0);

    background: $rd-navbar-megamenu-background;

    justify-content: center;

    @include media-breakpoint-up (xxxl) {
      padding: 10px 49px 30px;
    }

    > li {
      flex: 1 1 25%;
      max-width: 240px;

      & + li {
        border-top: 0;
      }

      // Megamenu column
      > ul {

        // Megamenu link
        a {
          display: block;
          padding: 13px 10px 13px 0;
          color: $rd-navbar-megamenu-item-color;
          background: $rd-navbar-megamenu-item-background;

          &:hover {
            color: $rd-navbar-megamenu-item-hover-color;
            background: $rd-navbar-megamenu-item-hover-background;
          }
        }
      }
    }
  }

  //RD Navbar Megamenu Banner
  .rd-navbar-megamenu-banner {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;

    > li:first-of-type {
      min-width: 300px;
      position: relative;
      overflow: hidden;

      > img {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate3d(0,-60%,0) scale(1.25);
        height: 100%;
        width: auto;
        max-width: none;
      }
    }

    >li:nth-child(n+1) {
      padding-top: 10px;
      padding-bottom: 10px;
    }

  }

  // RD Navbar Clone
  &.rd-navbar--is-clone {
    display: block;
    transform: translateY(-100%);

    &.rd-navbar--is-stuck {
      transform: translateY(0%);
    }
  }

  // RD Navbar Stuck
  &.rd-navbar--is-stuck,
  &.rd-navbar--is-clone {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    background: $rd-navbar-background;

    .rd-navbar-top-panel{
      display: none;
    }
  }

  // RD Navbar States
  .rd-navbar--has-dropdown {
    //position: relative;
  }
}


//=============      Fixed & Sidebar      ================
.rd-navbar-fixed,
.rd-navbar-sidebar {


}


// Scrollbar styles

.rd-navbar-fixed .rd-navbar-nav-wrap,
.rd-navbar-sidebar .rd-navbar-sidebar-inner  {
  width: $rd-navbar-nav-min-width;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  line-height: 34px;
  color: $rd-navbar-color;
  background: $rd-navbar-fixed-panel-background;
  box-shadow: $rd-navbar-fixed-shadow;
  z-index: 998;

  &:before,
  &:after {
    content: '';
    display: block;
    height: $rd-navbar-min-height;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: lighten($primary, 20%);
    border: none;
    border-radius: 0;
    opacity: .2;
  }

  &::-webkit-scrollbar-track {
    background: $primary;
    border: none;
    border-radius: 0;
  }
}

.rd-navbar-fixed .rd-navbar-nav,
.rd-navbar-sidebar .rd-navbar-nav {


  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    > li > a {
      padding-left: 20px;
    }

    ul > li > a {
      padding-left: 25px;
    }
  }
}

@import "rd-navbar_includes/_rd-navbar-static";
@import "rd-navbar_includes/_rd-navbar-fullwidth";
@import "rd-navbar_includes/_rd-navbar-fixed";
@import "rd-navbar_includes/_rd-navbar-sidebar";

// navbar variants
@import "rd-navbar_includes/_rd-navbar-default";
@import "rd-navbar_includes/_rd-navbar-centered";
@import "rd-navbar_includes/_rd-navbar-corporate";
@import "rd-navbar_includes/_rd-navbar-shop-header";


ul ul,
ul ol,
ol ul,
ol ol {
  padding-left: 0;
}
