/*
*
* 2.9  Comments
* --------------------------------------------------
*/

// Comments
.comment {
  @include display-flex;
  @include flex-direction(row);
  @include align-items(flex-start);
  text-align: left;
}

.comment-group {
  .comment-group {
    padding-left: 20px;

    .comment-group { padding-left: 10px; }
  }
}

.comment-body { padding-left: 12px; }

.comment-header {
  display: inline-table;
  @include group(8px);
}

.comment-avatar {
  img {
    width: auto;
    height: auto;
    max-width: 30px;
    border-radius: 50%;
  }
}

.comment-title {
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0;
  color: $secondary;
}

.comment-time { font-style: italic; }
.comment-text { color: $gray-darker; }
.comment-reply {
  font-style: italic;
  color: $gray-light;

  &::before {
    position: relative;
    top: 2px;
    display: inline-block;
    padding-right: 5px;
    content: '\f549';
    font: 400 20px/20px 'Material Design Icons';
    color: $gray-1;
  }

  @include media-breakpoint-up(md) {
    &::before {
      font-size: 28px;
      line-height: 28px;
    }
  }
}

.comment-link-reply {
  letter-spacing: .02em;
  color: $secondary;
  vertical-align: middle;

  &::before {
    position: relative;
    top: 2px;
    display: inline-block;
    padding-right: 5px;
    content: '\f549';
    font: 400 20px/20px 'Material Design Icons';
  }

  &:hover { color: $primary; }
  
  @include media-breakpoint-up(md) {
    &::before {
      font-size: 28px;
      line-height: 28px;
    }
  }
}

* + .comment-text { margin-top: 8px; }
* + .comment-footer { margin-top: 8px; }
* + .comment-group { margin-top: 30px; }
* + .comment { margin-top: 24px; }
* + .comment { margin-top: 24px; }
.comment + * { margin-top: 24px; }
* + .comment-link-reply { margin-top: 10px; }

.comment-group + .comment-group { margin-top: 30px; }

@include media-breakpoint-up(md) {
  .comment-group {
    .comment-group {
      padding-left: 80px;

      .comment-group {
        padding-left: 40px;
      }
    }
  }

  .comment-body { padding-left: 30px; }

  .comment-header {
    @include group(35px, 5px);
  }

  .comment-avatar {
    img {
      width: auto;
      max-width: 80px;
    }
  }

  .comment-title { font-size: 18px; }

  * + .comment-group { margin-top: 40px; }
}

@include media-breakpoint-up(xl) {
  .comment-group {
    .comment-group {
      padding-left: 110px;

      .comment-group {
        padding-left: 55px;
      }
    }
  }

  * + .comment-group { margin-top: 50px; }
  .comment-group + .comment-group { margin-top: 45px; }
}