/*
*
* Progress Bars
* --------------------------------------------------
*/



// Linear Progress Bars
// --------------------------------------------------

.progress-linear {
  position: relative;
  text-align: left;

  .progress-header{
    @include display-flex();
    @include align-items(center);
    @include justify-content(space-between);
  }

  .progress-bar-linear-wrap, .progress-bar-linear {
    height: 100%;
    border-radius: 3px;
  }

  .progress-bar-linear-wrap {
   background: $gray-lighter;
    height: 10px;
  }

  .progress-bar-linear {
    background-color: $primary;
    width: 0;
    transition: .5s all ease-in-out;
  }

  .progress-value{
    &:after{
      content:"%";
    }
  }
}

.progress-linear  + .progress-linear {
  margin-top: 15px;
}
