/**
* 2.3  Blogs Style
*/

/**
* Blog Inline
*/
.blog-inline {
  max-width: 318px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: $spacing-sm;
}

// Blog Inline Title
.blog-inline-title {
  color: $secondary;
  font-weight: 400;
  a {
    display: block;
  }
  > a {
    &, &:focus, &:active { color: inherit; }
    &:hover { color: $primary; }
  }
}

.blog-inline-meta {
  color: $gray-light;
  font-style: italic;
  margin-left: -20px;
  margin-bottom: -10px;
  transform: translateY(-10px);
  a {
    &, &:focus, &:active {      color: $gray-light;    }
    &:hover {      color: $primary;    }
  }
  > li {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  > li + li {
    &:after {
      content: '|';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      color: #979799;
    }
  }
}

* + .blog-inline { margin-top: 25px; }
* + .blog-inline-wrap { margin-top: 25px; }
* + .blog-inline-meta { margin-top: 5px; }

@include media-breakpoint-up(md) {
  * + .blog-inline { margin-top: 43px; }
  * + .blog-inline-wrap { margin-top: 43px; }
}

@include media-breakpoint-up(lg) {
  .blog-inline {
    margin-left: 0;
    margin-right: auto;
  }
}