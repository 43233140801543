/*
*
* Pagination custom
* --------------------------------------------------
*/
$pagination-font-size: 16px;
$pagination-lh-size: 20px;
$pagination-size: 50px;

$pagination-color: $white;
$pagination-bg: $secondary;

$pagination-hover-color: $white;
$pagination-hover-bg:  $primary;

$pagination-active-color: $pagination-hover-color;
$pagination-active-bg: $pagination-hover-bg;

$pagination-disabled-color: $white;
$pagination-disabled-bg: lighten($secondary, 20%);

.pagination-custom {
  display: inline-table;
  @include group(10px);

  > .page-item,
  > .page-item .page-link {
    border-radius: 10px;
  }

  > .page-item {
    font-family: $font-family-sec;
    font-size: $pagination-font-size;
    letter-spacing: $spacing-lg;

    min-width: $pagination-size;
    color: $pagination-color;
    background: $pagination-bg;
    vertical-align: middle;
  }
  
  > .page-item .page-link {
    display: block;
    height: $pagination-size;
    line-height: 30px;
    vertical-align: middle;
    color: inherit;
    background: inherit;
    text-align: center;
    border: none;
    margin-left: 0;

    &:hover {
      color: $pagination-hover-color;
      background: $pagination-hover-bg;
    }
  }
  
  > .page-item.disabled,
  > .page-item.active {
    pointer-events: none;
  }
  
  > .page-item.active {
    color: $pagination-active-color;
    background: $pagination-active-bg;
  }
  
  > .page-item.disabled {
    color: $pagination-disabled-color;
    background: $pagination-disabled-bg;
  }
  
  .pagination-control {
    a::before {
      color: inherit;
      font-family: 'Material Design Icons';
    }
    
    &:first-child {
      a::before {
        content: '\f14a';
      }
    }
    
    &:last-child {
      a::before {
        content: '\f14f';
      }
    }
  }
}


* + .pagination-custom { margin-top: 30px; }

@include media-breakpoint-up(xl) {
  * + .pagination-custom { margin-top: 50px; }
}